<template>
  <div>
    <div class="title">
      <div class="title_1" @click="goback">
        <img src="../../assets/zjt.png" alt="" />
        返回
      </div>
      <div class="title_2">考试历史</div>
    </div>
    <!-- <recordtime type="4" record="1"> </recordtime>
    <showtime type="4"> </showtime> -->
    <writetime type="4"></writetime>
    <div class="ul" v-if="list.length > 0">
      <div
        class="li"
        v-for="(item, index) in list"
        :key="index"
        @click="dets(item)"
      >
        <div class="top">
          <div class="l">{{ details.title }}综合测试</div>
          <div class="r">{{ item.score }}分</div>
        </div>
        <div class="footer">
          <div class="l">
            总分：{{ item.total_score }}&nbsp;&nbsp;&nbsp;及格分：{{
              item.pass_score
            }}
          </div>
          <div :class="item.status == 2 ? 'active r' : 'r'">
            {{ item.status == 1 ? "已通过" : "未通过" }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tac">—— 暂无记录 ——</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    ...mapActions(["getcomprehensiveCover_x"]),
    // 返回上一页
    goback() {
      this.$router.go(-1);
    },
    // 详情页
    dets(item) {
      this.$router.push({
        path: "/Layoutstudy/testhistorydetail",
        query: item,
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      details: (state) => state.details,
      det: (state) => state.det,
    }),
  },
  mounted() {
    // 列表数据
    this.getcomprehensiveCover_x({
      class_id: this.classid, //班级id
      student_id: this.userInfo.id, //学员id
    }).then((res) => {
      if (res.successCode == 200) {
        res.responseBody.record.forEach((e) => {
          e.start_time = e.start_time.substring(0, 16);
          if (e.end_time) {
            e.end_time = e.end_time.substring(0, 16);
          }
        });
        this.list = res.responseBody.record;
        console.log(this.list.length, "list");
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 45px;
  .title_1 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      margin-right: 5px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_2 {
    margin-left: 24px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.li {
  width: 100%;
  margin: 30px 0;
  margin-left: 32.5px;
  padding: 15px 10px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0px 2.5px 7px 1.5px rgba(148, 148, 148, 0.23);
  .top {
    display: flex;
    justify-content: space-between;
    .l {
      font-size: 12px;
      color: #c3c9c1;
      display: flex;
      align-items: center;
      &::before {
        margin-right: 10px;
        display: inline-block;
        border-radius: 50%;
        content: "";
        width: 5px;
        height: 5px;
        background-color: #0036ff;
      }
    }
    .r {
      font-size: 12px;
      color: #000;
    }
  }
  .footer {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l {
      font-size: 12px;
      color: #c3c9c1;
    }
    .r {
      color: #0c3eff;
      font-size: 12px;
      border-radius: 12px;
      padding: 5px 10px;
      background-color: #b5c5ff;
    }
    .active {
      color: #f42958 !important;
    }
  }
}
.tac {
  text-align: center;
  margin: 100px auto;
  font-size: 14px;
  color: #ccc;
}
</style>